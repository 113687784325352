import http from '../../app/services/httpServices';
import URL_UTILITY from '../../app/utility/url.utility';
 
const login = (context, user) => {
  return new Promise((resolve, reject) => {
    context.commit('authRequest');
    context.commit('loading', true);
    http
      .postApi(URL_UTILITY.authenticateUrl, user)
      .then(resp => {
        if (resp.status === 200) {
          context.commit('loading', false);
          // const userId = resp.data.data.user_id;
          // const token = resp.data.data.token;
          // const user = resp.data.data.user_type;
          // const responsibilitys = JSON.stringify(
          //   resp.data.data.responsibilitys
          // );
          // localStorage.setItem('responsibilities', responsibilitys);
          // sessionStorage.setItem('token', token);
          // sessionStorage.setItem('userId', userId);
          // context.commit('authSuccess', token, user);
          resolve(resp);
        } else {
          context.commit('loading', false);
          reject(resp.response.data.message);
        }
      })
      .catch(err => {
        context.commit('loading', false);
        context.commit('authError');
        sessionStorage.removeItem('token');
        sessionStorage.removeItem('userId');
        reject(err);
      });
  });
};
const validateEmail = ({ commit }, user_email) => {
  const user = {
    user_email: user_email
  };
  return new Promise((resolve, reject) => {
    commit('verify_email');
    commit('loading', true);
    http
      .postApi(URL_UTILITY.email, user)
      .then(resp => {
        if (resp.status == 200) {
          commit('set_error', '');
          resolve(resp);
          commit('loading', false);
        }
      })
      .catch(err => {
        commit('loading', false);
        let message = err.message;
        commit('set_error', message);
        reject(err);
      });
  });
};
const setToken = context => {
  context.commit('setToken', sessionStorage.getItem('token'));
};
const logout = context => {
  return new Promise(resolve => {
    // localStorage.removeItem('responsibilities');
    // sessionStorage.removeItem('userId');
    // localStorage.removeItem('notification');
    // context.commit('logout');
    http.postApi(URL_UTILITY.authLogoutUrl).then(resp => {
      if (resp.status === 200) {
        context.commit('loading', false);
        sessionStorage.removeItem('token');
        localStorage.removeItem('responsibilities');
        sessionStorage.removeItem('userId');
        localStorage.removeItem('notification');
        resolve();
      }
    });
  });
};
 
const getUserDetail = () => {
  const url = URL_UTILITY.getUserDetailUrl;
  return http.getApi(url);
};
 
const passwordReset = (context, userEmail) => {
  const url = URL_UTILITY.passwordResetUrl;
  return http.postApi(url, userEmail);
};
 
const confirmOTP = (context, resetPassword) => {
  const url = URL_UTILITY.verifyOtpUrl;
  return http.postApi(url, resetPassword);
};
const setNewPassword = (context, newPassword) => {
  const url = URL_UTILITY.newPasswordUrl;
  return http.postApi(url, newPassword);
};
const setUserId = (context, userId) => {
  context.commit('setUserId', userId);
};
const setUserName = (context, userName) => {
  context.commit('setUserName', userName);
};
const setUserDtl = (context, userDtl) => {
  context.commit('setUserDtl', userDtl);
};
const changeExistingPassword = (context, payload) => {
  return http.postApi(URL_UTILITY.changePasswordUrl, payload);
};
const verify2FAOTP = (context, payload) => {
  const url = URL_UTILITY.verify2FAOTPUrl;
  return http.postApi(url, payload);
};
const resend2FAOTP = (context, payload) => {
  const url = URL_UTILITY.resend2FAOTPUrl;
  return http.postApi(url, payload);
};
const getGmailAuth = () => {
  const url = URL_UTILITY.gmailAuthUrl;
  return http.getApi(url);
};
export default {
  login,
  validateEmail,
  setToken,
  logout,
  getUserDetail,
  passwordReset,
  confirmOTP,
  setNewPassword,
  setUserId,
  setUserName,
  setUserDtl,
  changeExistingPassword,
  verify2FAOTP,
  resend2FAOTP,
  getGmailAuth
};
