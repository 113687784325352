import http from '../../../../app/services/httpServices';
import URL_UTILITY from '../../../../app/utility/url.utility';

const getItcDataUpdation = (context, payload) => {
  return http.getApi(URL_UTILITY.getItcDataUpdationUrl, payload);
};

const uploadDataUpdation = (context, excelImportId) => {
  return http.postApi(
    URL_UTILITY.getItcDataUpdationUrl +
      '/' +
      excelImportId +
      '/' +
      'excel-upload'
  );
};

const addEditItcDataUpdation = (context, payload) => {
  return http.postApi(URL_UTILITY.getItcDataUpdationUrl, payload);
};

const getGstr1Summary = (context, payload) => {
  return http.getApi(URL_UTILITY.getGstUrl + '/gstr1/summary', payload);
};
const salesRegister = (context, payload) => {
  return http.getApi(URL_UTILITY.getGstUrl + '/gst-sales-register', payload);
};
const gstr1ReturnSummary = (context, payload) => {
  return http.getApi(URL_UTILITY.getGstUrl + '/gstr1/returnsummary', payload);
};

const gstrhHsnSummary = (context, payload) => {
  return http.getApi(URL_UTILITY.getGstUrl + '/gstr1/hsnsummary', payload);
};

const b2csHsnSummary = (context, payload) => {
  return http.getApi(URL_UTILITY.getGstUrl + '/gstr1/b2cssummary', payload);
};

const gstr1SummarySave = (context, payload) => {
  return http.getApi(
    URL_UTILITY.getGstUrl + '/gstr1/gstr1SaveRequest',
    payload
  );
};

const addEditSalesRegister = (context, payload) => {
  return http.postApi(URL_UTILITY.getGstUrl + '/gst-sales-register', payload);
};

const getDocSeqAsPerBooksData = (context, payload) => {
  return http.getApi(URL_UTILITY.getGstUrl + '/doc-sequence-books', payload);
};

const getDocSeqAsPerGovData = (context, payload) => {
  return http.getApi(URL_UTILITY.getGstUrl + '/doc-sequence-gov', payload);
};

const addEditDocSeq = (context, payload) => {
  return http.postApi(
    URL_UTILITY.getGstUrl + '/doc-sequence-books-update',
    payload
  );
};

const deleteDocumentSequence = (context, payload) => {
  return http.deleteApi(
    URL_UTILITY.getGstUrl +
      '/doc-sequence-books-delete/' +
      payload.document_issue_id
  );
};

const getR1SummaryAllInvoices = (context, payload) => {
  return http.getApi(URL_UTILITY.getGstUrl + '/gstr1/invoicesall', payload);
};
const getR1SummaryWithDifference = (context, payload) => {
  return http.getApi(URL_UTILITY.getGstUrl + '/gstr1/invoiceswithdifference', payload);
};
const getR1SummaryWithoutDifference = (context, payload) => {
  return http.getApi(URL_UTILITY.getGstUrl + '/gstr1/invoiceswithoutdifference', payload);
};
const uploadExcelSalesRegiater = (context, excelImportId) => {
  return http.postApi(
    URL_UTILITY.getGstPerformanceMatrix +
      '-sales-upgrades/' +
      excelImportId +
      '/' +
      'excel-upload'
  );
};
const updateItemDescHsnSummary = (context, payload) => {
  return http.putApi(URL_UTILITY.getGstUrl + '/gstr1/hsnsummary', payload);
};
const uploadGstSalesRegister = (context, payload) => {
  const url =
    URL_UTILITY.getGstPerformanceMatrix +
    '-fusion-uploader/' +
    payload.excel_import_id +
    '/excel-upload';
  return http.postApi(url);
};
export default {
  getItcDataUpdation,
  addEditItcDataUpdation,
  uploadDataUpdation,
  getGstr1Summary,
  salesRegister,
  gstr1ReturnSummary,
  gstrhHsnSummary,
  b2csHsnSummary,
  gstr1SummarySave,
  addEditSalesRegister,
  getDocSeqAsPerBooksData,
  getDocSeqAsPerGovData,
  addEditDocSeq,
  deleteDocumentSequence,
  getR1SummaryAllInvoices,
  getR1SummaryWithDifference,
  getR1SummaryWithoutDifference,
  uploadExcelSalesRegiater,
  updateItemDescHsnSummary,
  uploadGstSalesRegister
};
